import React, { Fragment, memo, useCallback, useState } from 'react';

import { Marker } from './Marker/Marker';
import { getOfferPosition } from './utils/getOfferPosition';
import { useMapSearchResults } from './utils/useMapSearchResults';

type MarkersProps = {
  onSelectOffer: (offerId: string) => void;
  selectedOfferId: string | undefined;
};

export const Markers = memo<MarkersProps>(({ selectedOfferId, onSelectOffer }) => {
  const [visitedOfferIds, setVisitedOfferIds] = useState<Set<string>>(new Set());
  const offers = useMapSearchResults();

  const onMarkerClick = useCallback(
    (offerId: string) => {
      onSelectOffer(offerId);
      setVisitedOfferIds((prev) => new Set(prev).add(offerId));
    },
    [onSelectOffer],
  );

  return (
    <Fragment>
      {offers.map((offer, index) => {
        const id = offer.hotel.accommodation.id;
        const selected = selectedOfferId === id;

        return (
          <Marker
            key={id}
            id={id}
            index={selected ? 0 : index + 1}
            position={getOfferPosition(offer)}
            pricing={offer.pricing}
            onClick={onMarkerClick}
            selected={selected}
            visited={visitedOfferIds.has(id)}
          />
        );
      })}
    </Fragment>
  );
});
