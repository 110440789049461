import { Map as GoogleMap, useMap } from '@vis.gl/react-google-maps';
import { MapCameraChangedEvent } from '@vis.gl/react-google-maps/dist/components/map/use-map-events';
import React, { memo, PropsWithChildren, useCallback, useMemo } from 'react';

import { useViewport } from './utils/useViewport';
import { sendEvent } from '@Core/tracking/sendEvent';
import { TrackingEvent } from '@Core/tracking/types';
import { staticDebounce } from '@Core/utils';
import { useSearchSelectionStore } from '@Stores/StoreContext';

const mapId = '47f556cf5686c43a';

const restriction = {
  strictBounds: true,
  // The whole world bounds
  latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
};

export const Map = memo<PropsWithChildren<{ onClick: () => void }>>(({ children, onClick }) => {
  const defaultBounds = useViewport();
  const map = useMap();
  const setFilterSelection = useSearchSelectionStore((state) => state.setFilterSelection);
  const debouncedBoundsChange = useMemo(
    () => staticDebounce(setFilterSelection, 250),
    [setFilterSelection],
  );

  const handleBoundsChange = useCallback(
    ({ detail: { bounds } }: MapCameraChangedEvent) => {
      debouncedBoundsChange({ location: bounds });
    },
    [debouncedBoundsChange],
  );

  const handleDragEnd = useCallback(() => {
    if (map) {
      const payload = map.getBounds();
      sendEvent({
        event: TrackingEvent.map,
        action: 'drag-viewport',
        payload,
      });
    }
  }, [map]);

  if (!defaultBounds) {
    return null;
  }

  return (
    <GoogleMap
      onClick={onClick}
      defaultBounds={defaultBounds}
      mapId={mapId}
      clickableIcons={false}
      gestureHandling="greedy"
      isFractionalZoomEnabled
      mapTypeControlOptions={{ position: google.maps.ControlPosition.INLINE_START_BLOCK_START }}
      zoomControlOptions={{ position: google.maps.ControlPosition.INLINE_END_BLOCK_START }}
      streetViewControlOptions={{ position: google.maps.ControlPosition.INLINE_END_BLOCK_START }}
      fullscreenControlOptions={{ position: google.maps.ControlPosition.INLINE_END_BLOCK_START }}
      restriction={restriction}
      onBoundsChanged={handleBoundsChange}
      onDragend={handleDragEnd}
      sx={{
        isolation: 'isolate',
        width: '100%',
        height: '100%',
        'div[class$="-marker-view"]': {
          outline: 'none',
        },
      }}
    >
      {children}
    </GoogleMap>
  );
});
