import { Colors, Effects } from '@loveholidays/design-system';

type Color = keyof Colors;

export type ColorSchema = {
  border: Color;
  background: Color;
  text: Color;
  dot: Color;
  shadow: keyof Effects;
};

export const colorSchemas: Record<'default' | 'hovered' | 'selected' | 'visited', ColorSchema> = {
  default: {
    border: 'interactiveDefault',
    background: 'backgroundWhite',
    text: 'textDefault',
    dot: 'interactiveDefault',
    shadow: 'elevationFloating',
  },
  hovered: {
    border: 'interactiveHover',
    background: 'backgroundWhite',
    text: 'textDefault',
    dot: 'interactiveHover',
    shadow: 'elevationHover',
  },
  selected: {
    border: 'interactiveSelected',
    background: 'interactiveSelected',
    text: 'backgroundWhite',
    dot: 'backgroundWhite',
    shadow: 'elevationHover',
  },
  visited: {
    border: 'strokeDarkneutral',
    background: 'backgroundLightneutral',
    text: 'textDefault',
    dot: 'strokeDarkneutral',
    shadow: 'elevationHover',
  },
};

export function getMarkerColorSchema({
  selected,
  visited,
}: {
  selected?: boolean;
  visited?: boolean;
}): typeof colorSchemas.default {
  if (selected) {
    return colorSchemas.selected;
  }

  if (visited) {
    return colorSchemas.visited;
  }

  return colorSchemas.default;
}
