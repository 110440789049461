import { AdvancedMarker } from '@vis.gl/react-google-maps';
import React, { FC, memo, useCallback } from 'react';

import { getMarkerColorSchema } from './getMarkerColorSchema';
import { MarkerContent } from './MarkerContent';
import { Pricing } from '@AuroraTypes';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';

type MarkerProps = {
  index: number;
  id: string;
  pricing: Pricing;
  onClick: (id: string) => void;
  selected?: boolean;
  visited?: boolean;
  position: {
    lat: number;
    lng: number;
  };
};

export const Marker: FC<MarkerProps> = memo(
  ({ index, id, position, pricing, onClick, selected, visited }) => {
    const colorSchema = getMarkerColorSchema({ selected, visited });
    // TODO: integrate hover styles

    const onPinClick = useCallback(() => {
      trackEventClick('map-search-pin-clicked');
      onClick(id);
    }, [id, onClick]);

    return (
      <AdvancedMarker
        position={position}
        onClick={onPinClick}
        zIndex={999999 - index}
        gmpClickable
      >
        <MarkerContent
          pricing={pricing}
          colorSchema={colorSchema}
        />
      </AdvancedMarker>
    );
  },
);
