import { DynamicPackageResult, DynamicPackageResultOrPromotionalCard } from '@AuroraTypes';
import { SomeRequired } from '@Core/types';

type Accommodation = DynamicPackageResult['hotel']['accommodation'];

type PackageWithCoordinates = DynamicPackageResult & {
  hotel: DynamicPackageResult['hotel'] & {
    accommodation: SomeRequired<Accommodation, 'coordinates'>;
  };
};

export function filterSearchResults(searchResults: DynamicPackageResultOrPromotionalCard[]) {
  return searchResults.filter(
    (result): result is PackageWithCoordinates =>
      'hotel' in result && !!result.hotel.accommodation.coordinates,
  );
}
