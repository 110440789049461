import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useMemo } from 'react';

import { filterSearchResults } from './filterSearchResults';
import { getOfferPosition } from './getOfferPosition';
import { useSearchResultsStore } from '@Stores/StoreContext';

// This represents the default bounds for the map, which is the whole of Europe.
const defaultBounds = { south: 34, west: -11, north: 65, east: 55 };

function useSearchListResults() {
  const searchResults = useSearchResultsStore((state) => state.results);

  return useMemo(() => filterSearchResults(searchResults), [searchResults]);
}

export function useViewport(): google.maps.LatLngBoundsLiteral | undefined {
  const coreLib = useMapsLibrary('core');
  const offers = useSearchListResults();

  return useMemo(() => {
    if (!coreLib) {
      return;
    }

    switch (offers.length) {
      case 0: {
        return defaultBounds;
      }

      case 1: {
        // When there is exactly one offer, create a viewport around its position.
        // The viewport's boundaries are adjusted by 0.02 degrees from the offer's coordinates,
        // creating a small buffer around the offer on the map.
        const { lat, lng } = getOfferPosition(offers[0]);

        return {
          south: lat - 0.02,
          west: lng - 0.02,
          north: lat + 0.02,
          east: lng + 0.02,
        };
      }

      default: {
        return offers
          .reduce(
            (bounds, offer) => bounds.extend(getOfferPosition(offer)),
            new coreLib.LatLngBounds(),
          )
          .toJSON();
      }
    }
  }, [offers, coreLib]);
}
