import { ModalHeader } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { MapSearch } from './MapSearch';
import { MapSearchProvider } from './MapSearchContext';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useSearchAvailabilityStore, useSearchResultsStore } from '@Stores/StoreContext';

type MapSearchProps = {
  isShown: boolean;
  onClose: () => void;
};

export const MapSearchModal: React.FC<MapSearchProps> = ({ isShown, onClose }) => {
  const { t } = useTranslation();

  const totalResults = useSearchResultsStore((state) => state.totalResults);
  const isFetching = useSearchAvailabilityStore(
    ({ isAvailabilityFetching }) => isAvailabilityFetching,
  );

  return (
    <LoadableModal
      onClose={onClose}
      show={isShown}
      isFullScreen
      noPadding
      width="fullcontentmaxwidth"
      Header={
        <ModalHeader
          layout="medium"
          label={
            isFetching ? t('findingYourHoliday') : t('xHolidaysFound', { count: totalResults })
          }
          onClose={onClose}
        />
      }
      data-id="map-search-modal"
      sx={{ touchAction: 'pan-x pan-y' }}
      Content={
        <MapSearchProvider>
          <MapSearch />
        </MapSearchProvider>
      }
    />
  );
};
