import { keyframes } from '@emotion/react';
import { loveholidaysTheme } from '@loveholidays/design-system';
import React, { FC } from 'react';
import { SxStyleProp } from 'theme-ui';

import { useSearchResultsStore } from '@Stores/StoreContext';

const animation = keyframes`
  0% {
    background-color: ${loveholidaysTheme.colors.backgroundPrimary}
  }

  50%, 100% {
    background-color: ${loveholidaysTheme.colors.backgroundPrimarymedium}
  }
`;

const dotStyles: SxStyleProp = {
  display: 'block',
  width: '8px',
  height: '8px',
  borderRadius: 'rounded',
  backgroundColor: 'backgroundPrimarymedium',
  animation: `${animation} 0.5s infinite linear alternate`,
};

export const LoadingIndicator: FC = () => {
  const isLoading = useSearchResultsStore((state) => state.mapSearchFetching);

  if (!isLoading) {
    return null;
  }

  return (
    <div
      sx={{
        position: 'absolute',
        top: 24,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '64',
        height: '40',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'backgroundLightneutral',
        borderRadius: '12',
        backgroundColor: 'backgroundWhite',
        boxShadow:
          '0px 0px 0px 0px rgba(97, 96, 93, 0.08), 0px 1px 2px 0px rgba(97, 96, 93, 0.08), 0px 3px 3px 0px rgba(97, 96, 93, 0.07), 0px 7px 4px 0px rgba(97, 96, 93, 0.04), 0px 12px 5px 0px rgba(97, 96, 93, 0.01), 0px 18px 5px 0px rgba(97, 96, 93, 0.00)',
        pointerEvents: 'none',
      }}
    >
      <div
        sx={{
          ...dotStyles,
          position: 'relative',
          backgroundColor: 'backgroundPrimary',
          animationDelay: '0.25s',
          '&::before': {
            ...dotStyles,
            content: '""',
            position: 'absolute',
            left: -16,
            animationDelay: '0s',
          },
          '&::after': {
            ...dotStyles,
            content: '""',
            position: 'absolute',
            right: -16,
            animationDelay: '0.5s',
          },
        }}
      ></div>
    </div>
  );
};
