import { useApiIsLoaded } from '@vis.gl/react-google-maps';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { LoadingIndicator } from './LoadingIndicator';
import { Map } from './Map';
import { Markers } from './Markers';
import { SelectedOffer } from './SelectedOffer';
import { SearchFilters } from '@Components/LayoutComponents/SearchResultsComponent/filters/SearchFilters';
import { sendEvent } from '@Core/tracking/sendEvent';
import { TrackingEvent, WebEventCategory } from '@Core/tracking/types';

type ActionType = 'open' | 'closed' | 'broad-search-message-shown';

const sendMapSearchEvent = (action: ActionType) => {
  sendEvent({
    event: TrackingEvent.map,
    category: WebEventCategory.view,
    action,
  });
};

export const MapSearch: FC = () => {
  const isApiLoaded = useApiIsLoaded();
  const [selectedOfferId, setSelectedOfferId] = useState<string | undefined>(undefined);

  const onMapClick = useCallback(() => {
    setSelectedOfferId(undefined);
  }, []);

  useEffect(() => {
    sendMapSearchEvent('open');

    return () => {
      sendMapSearchEvent('closed');
    };
  }, []);

  if (!isApiLoaded) {
    return null;
  }

  return (
    <div
      sx={{
        height: '100%',
        display: 'flex',
      }}
    >
      <SearchFilters
        sx={{
          overflowY: [null, null, 'auto'],
          flex: [null, null, '0 0 380px'],
          paddingLeft: [null, null, 's'],
          paddingRight: [null, null, 'l'],
        }}
      />

      <div
        sx={{
          position: 'relative',
          flex: 1,
        }}
      >
        <Map onClick={onMapClick}>
          <Markers
            onSelectOffer={setSelectedOfferId}
            selectedOfferId={selectedOfferId}
          />
        </Map>

        <SelectedOffer selectedOfferId={selectedOfferId} />
        <LoadingIndicator />
      </div>
    </div>
  );
};
