import { Label } from '@loveholidays/design-system';
import React, { FC, memo } from 'react';

import { ColorSchema } from './getMarkerColorSchema';
import { Pricing } from '@AuroraTypes';
import { PassengerPrice } from '@Components/Price/PassengerPrice';

type MarkerContentProps = {
  pricing: Pricing;
  colorSchema: ColorSchema;
};

export const MarkerContent: FC<MarkerContentProps> = memo(({ pricing, colorSchema }) => (
  <div
    sx={{
      position: 'relative',
      paddingX: '2xs',
      paddingY: '4xs',
      isolation: 'isolate',
      borderRadius: 16,
    }}
  >
    <span
      sx={{
        position: 'absolute',
        inset: 0,
        borderWidth: 'outlinedStrokeWeight',
        borderStyle: 'solid',
        borderColor: colorSchema.border,
        borderRadius: 16,
        background: colorSchema.background,
      }}
    />
    <Label
      variant="mediumbold"
      as="span"
      sx={{ position: 'relative', zIndex: 1, color: colorSchema.text }}
    >
      <PassengerPrice
        value={pricing.total}
        suffixType="short"
      />
    </Label>
    <span
      sx={{
        position: 'absolute',
        bottom: -5,
        left: '50%',
        transform: 'translateX(-50%)',
        height: 6,
        width: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colorSchema.border,
        borderBottomLeftRadius: 'rounded',
        borderBottomRightRadius: 'rounded',
        background: colorSchema.background,
        borderTopColor: colorSchema.background,
      }}
    />
    <span
      sx={{
        position: 'absolute',
        bottom: -2,
        left: '50%',
        transform: 'translate(-50%)',
        display: 'block',
        height: 4,
        width: 4,
        background: colorSchema.dot,
        borderRadius: 'rounded',
      }}
    />
  </div>
));
