import { useMemo } from 'react';

import { filterSearchResults } from './filterSearchResults';
import { useSearchResultsStore } from '@Stores/StoreContext';

export function useMapSearchResults(): ReturnType<typeof filterSearchResults> {
  const searchResults = useSearchResultsStore((state) => state.mapSearchResults);

  return useMemo(() => filterSearchResults(searchResults), [searchResults]);
}
