import { APIProvider } from '@vis.gl/react-google-maps';
import React, { FC } from 'react';

import { useAppContext } from '@Contexts/contexts';

export const MapSearchProvider: FC = ({ children }) => {
  const { googleMapsApiKey, site } = useAppContext();

  return (
    <APIProvider
      apiKey={googleMapsApiKey}
      language={site.locale}
      libraries={['maps']}
    >
      {children}
    </APIProvider>
  );
};
